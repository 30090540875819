import React from 'react';
import styles from '../styles.module.css';

export default function NotFound() {
	return (
		<main className={styles.main}>
			<h1>404 | Não encontrado.</h1>
		</main>
	);
}
