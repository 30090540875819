import React from 'react';
import styles from '../styles.module.css';

export default function Rendering() {
	return (
		<main className={styles.main}>
			<h1>Carregando...</h1>
		</main>
	);
}
